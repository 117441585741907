import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import translate from '../../translate';

function ColorSelector(props) {
  const {
    t,
    id,
    onColorChange,
    value,
    disabled,
    label,
    containerClassName,
    labelClassName,
    inputValueContainerClassName,
    inputValueClassName,
    inputColorClassName,
    showErrorMessage,
  } = props;
  const [color, setColor] = useState(value || '');
  useEffect(() => {
    setColor(value);
  }, [value]);
  const [isValid, setIsValid] = useState(true);

  const hexColorRegex = /^#(?:[0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;

  const handleInputChange = event => {
    const inputValue = event.target.value;
    setColor(inputValue);
    setIsValid(hexColorRegex.test(inputValue));
    onColorChange(inputValue);
  };
  return (
    <div className="color-wrapper">
      <div id={`colorContainer-${id}`} className={containerClassName}>
        {label ? (
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
        ) : null}
        <div className={inputValueContainerClassName}>
          <input
            type="text"
            value={color}
            className={inputValueClassName}
            onChange={handleInputChange}
          />
          <input
            type="color"
            id={id}
            name={id}
            className={inputColorClassName}
            onChange={e => onColorChange(e.target.value)}
            disabled={disabled}
            value={value}
          />
        </div>
      </div>
      {showErrorMessage && !isValid && (
        <span className="m--font-danger">{t('error.invalid.hex.color')}</span>
      )}
    </div>
  );
}

ColorSelector.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputValueContainerClassName: PropTypes.string,
  inputValueClassName: PropTypes.string,
  inputColorClassName: PropTypes.string,
  showErrorMessage: PropTypes.bool,
};
ColorSelector.defaultProps = {
  label: null,
  value: null,
  disabled: false,
  containerClassName: 'color-container',
  labelClassName: 'color-label',
  inputValueContainerClassName: 'input-value-container',
  inputValueClassName: 'form-control m-input input-value',
  inputColorClassName: 'input-color',
  showErrorMessage: false,
};

// eslint-disable-next-line no-undef
export default translate(ColorSelector);
