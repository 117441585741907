import PropTypes from 'prop-types';

const propType = {
  favicon: PropTypes.string,
  squareLogo: PropTypes.string,
  horizontalLogo: PropTypes.string,
  headBackground: PropTypes.string,
  headBackgroundResponsive: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  appName: PropTypes.string,
};

const defaultProp = {
  favicon: null,
  squareLogo: null,
  horizontalLogo: null,
  headBackground: null,
  headBackgroundResponsive: null,
  primaryColor: null,
  secondaryColor: null,
  appName: null,
};
export default { propType, defaultProp };
