import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import organizationProp from '../../commonComponents/propTypes/organizationProp';
import TabBranding from '../../sections/settings/configurations/general/TabBranding';

const TabBrandingWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { organization } = props;
  const { branding: organizationBranding } = organization || {};

  const [branding, setBranding] = useState(organizationBranding || null);
  useEffect(() => propagateChanges(branding), [branding]);
  const handleChanges = useCallback(
    field => value => {
      setBranding(prevBranding => ({
        ...prevBranding,
        [field]: value,
      }));
    },
    [],
  );

  useImperativeHandle(ref, () => ({
    getValue: () => branding,
    setValue: newBranding => setBranding(newBranding),
  }));

  return (
    <>
      <TabBranding
        {...childProps}
        branding={branding}
        onChange={handleChanges}
      />
    </>
  );
});

TabBrandingWebHandler.propTypes = {
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
};

TabBrandingWebHandler.defaultProps = {};

window.TabBranding = class TabBrandingRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'TabBranding container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    const dataCSRF = document.querySelector('script[data-csrf]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }
    let _csrf = null;
    if (dataCSRF) {
      _csrf = dataCSRF.getAttribute('data-csrf');
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <TabBrandingWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        _csrf={_csrf}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
