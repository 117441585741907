import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import ImageInput from '../../../../commonComponents/forms/ImageInput';
import BrandingProp from '../propTypes/brandingProp';
import ColorSelector from '../../../../commonComponents/forms/inputs/ColorSelector';
import FormInput from '../../../../commonComponents/forms/FormInput';
import {
  compose,
  handleImageUpload,
} from '../../../../services/utilityFunctions';
import Notifications from '../../../../services/notifications';
import withCSRF from '../../../../commonComponents/withCSRF';
import Loader from '../../../../services/loader';

function TabBranding(props) {
  const { t, _csrf, branding, onChange, tabId, inputId, isVOD } = props;
  const {
    favicon = null,
    squareLogo = null,
    horizontalLogo = null,
    headBackground = null,
    headBackgroundResponsive = null,
    primaryColor = '',
    primaryText = '',
    appName = null,
  } = branding || {};

  // region Loader
  const loader = useRef(new Loader('#tabBranding'));
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (loader.current) {
      if (isLoading) {
        loader.current.show();
      } else {
        loader.current.hide();
      }
    }
  }, [isLoading]);
  // endregion Loader

  const handleImageChange = id => async img => {
    try {
      setLoading(true);
      const data = img
        ? await handleImageUpload(t, _csrf, img, false)
        : { url: null };
      onChange(id)(data.url);
      setLoading(false);
    } catch (error) {
      Notifications.showErrorFromRequest(error, 'error_imageUpload');
      setLoading(false);
    }
  };

  const getId = field => `${tabId}_${field}`;

  const headerBackgroundPlaceholder = isVOD
    ? '/image/placeholder/500/190?customMessage=1440x550&fontSize=45'
    : '/image/placeholder/760/150?customMessage=6:1&fontSize=45';
  const headerBackgroundResponsivePlaceholder = isVOD
    ? '/image/placeholder/670/250?customMessage=375x141&fontSize=45'
    : '/image/placeholder/690/150?customMessage=300:67&fontSize=45';
  return (
    <div id={tabId}>
      <input
        type="hidden"
        id={inputId}
        name={inputId}
        value={JSON.stringify(branding)}
      />
      <section>
        {!isVOD && <h4>{t('tenant_branding')}</h4>}
        <ImageInput
          id={getId('favicon')}
          inputId={getId('favicon')}
          name={getId('favicon')}
          type="image"
          initialValue={favicon}
          placeholder="/image/placeholder/200/200?customMessage=1:1&fontSize=45"
          onChange={handleImageChange('favicon')}
          inputProps={{
            cropAspect: 1,
            cropLocked: false,
            t,
          }}
          t={t}
          label={t('tenant_favIcon')}
          labelClassName="col-2 col-lg-1 text-right"
          containerClassName="col-2"
          groupClassName="form-group m-form__group row"
          showSelectImageBtn
          hint={t('tenant_favIcon_hint')}
        />
        <div className="logo-containers">
          {!isVOD && (
            <div className="squareLogo-container">
              <ImageInput
                id={getId('squareLogo')}
                inputId={getId('squareLogo')}
                name={getId('squareLogo')}
                type="image"
                initialValue={squareLogo}
                placeholder="/image/placeholder/170/170?customMessage=1:1&fontSize=45"
                onChange={handleImageChange('squareLogo')}
                inputProps={{
                  cropAspect: 1,
                  cropLocked: false,
                  t,
                }}
                t={t}
                label={t('tenant_squareLogo')}
                labelClassName="col-3 text-right"
                containerClassName="col-6"
                groupClassName="form-group m-form__group row"
                showSelectImageBtn
                hint={t('tenant_squareLogo_hint')}
              />
            </div>
          )}
          <div
            className={`horizontalLogo-container ${
              isVOD && 'vod-horizontalLogo-container'
            }`}
          >
            <ImageInput
              id={getId('horizontalLogo')}
              inputId={getId('horizontalLogo')}
              name={getId('horizontalLogo')}
              type="image"
              initialValue={horizontalLogo}
              placeholder="/image/placeholder/360/174?customMessage=350x170&fontSize=45"
              onChange={handleImageChange('horizontalLogo')}
              inputProps={{
                cropWidth: 350,
                cropHeight: 170,
                cropLocked: false,
                t,
              }}
              t={t}
              label={t('tenant_horizontalLogo')}
              labelClassName="col-3 text-right"
              containerClassName="col-6"
              groupClassName="form-group m-form__group row"
              showSelectImageBtn
              hint={t('tenant_horizontalLogo_hint')}
            />
          </div>
        </div>
        <ImageInput
          id={getId('headBackground')}
          inputId={getId('headBackground')}
          name={getId('headBackground')}
          type="image"
          initialValue={headBackground}
          placeholder={headerBackgroundPlaceholder}
          onChange={handleImageChange('headBackground')}
          inputProps={{
            cropWidth: isVOD ? 1440 : 1500,
            cropHeight: isVOD ? 550 : 250,
            cropLocked: false,
            t,
          }}
          t={t}
          label={t('tenant_backgroundHeader')}
          labelClassName="col-2 col-lg-1 text-right"
          containerClassName="col-8"
          groupClassName="form-group m-form__group row"
          showSelectImageBtn
          hint={t('tenant_backgroundHeader_hint')}
        />
        <ImageInput
          id={getId('headBackgroundResponsive')}
          inputId={getId('headBackgroundResponsive')}
          name={getId('headBackgroundResponsive')}
          type="image"
          initialValue={headBackgroundResponsive}
          placeholder={headerBackgroundResponsivePlaceholder}
          onChange={handleImageChange('headBackgroundResponsive')}
          inputProps={{
            cropWidth: isVOD ? 375 : 300,
            cropHeight: isVOD ? 141 : 67,
            cropLocked: false,
            t,
          }}
          t={t}
          label={t('tenant_backgroundHeaderResponsive')}
          labelClassName="col-2 col-lg-1 text-right"
          containerClassName="col-9"
          groupClassName="form-group m-form__group row"
          showSelectImageBtn
          hint={t('tenant_backgroundHeaderResponsive_hint')}
        />
        <div className="branding-mode">
          <div className="lightMode">
            <ColorSelector
              t={t}
              id={getId('primaryColor')}
              onColorChange={value => onChange('primaryColor')(value)}
              value={primaryColor || ''}
              label={t('tenant_primaryColor')}
              hint={t('tenant_primaryColor_hint')}
            />
            <ColorSelector
              t={t}
              id={getId('primaryText')}
              onColorChange={value => onChange('primaryText')(value)}
              value={primaryText || ''}
              label={t('tenant_primaryText')}
              hint={t('tenant_primaryText_hint')}
            />
          </div>
        </div>
        {!isVOD && (
          <FormInput
            id={getId('appName')}
            value={appName}
            name={getId('appName')}
            labelClassName="appName-label"
            containerClassName="appName-inputContainer"
            groupClassName="appName-container"
            type="text"
            groupProps={{ className: 'appName-container' }}
            label={t('tenant_appName')}
            placeholder={t('tenant_appName_placeholder')}
            onChange={onChange('appName')}
          />
        )}
      </section>
    </div>
  );
}

TabBranding.propTypes = {
  t: PropTypes.func.isRequired,
  tabId: PropTypes.string,
  inputId: PropTypes.string,
  isVOD: PropTypes.bool,
  _csrf: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  branding: PropTypes.shape(BrandingProp.propType),
};
TabBranding.defaultProps = {
  branding: PropTypes.shape(BrandingProp.defaultProp),
  tabId: 'tabBranding',
  inputId: 'branding',
  isVOD: false,
};

export default compose(translate, withCSRF)(TabBranding);
